import { useOrganization } from "@clerk/nextjs";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useToast } from "@winclap-platform/ui/hooks/use-toast";
import axios, { AxiosError } from "axios";
import { useMemo } from "react";
import { clerk } from "~/context/auth/auth-provider";
import { getAppType } from "~/utils/apps";
import { countryMap } from "~/utils/countries";
import { format } from "date-fns";

import { App } from "@winclap-platform/bff/src/functions/get-apps";
import { BusinessUnit } from "@winclap-platform/bff/src/functions/get-business-units-by-appid";
import { ParsedReport } from "@winclap-platform/bff/src/functions/get-report";
import { Schedule } from "@winclap-platform/bff/src/functions/reports-scheduler/get-reports-schedule";
import { BaseReport, ReportInput } from "@winclap-platform/models/schemas/reports";
import { Comment, InputComment } from "@winclap-platform/models/schemas/comments";
import { ScheduleInput } from "@winclap-platform/models/schemas/schedules";
import { queryClient } from "~/pages/_app";
import { Button } from "@winclap-platform/ui/components/button";

const API_URL = process.env.NEXT_PUBLIC_PLATFORM_BFF;

const client = axios.create({
  baseURL: API_URL,
});

client.interceptors.request.use(async (config) => {
  const token = await clerk.session?.getToken({ template: "platform-bff" });
  config.headers.set("Authorization", `Bearer ${token}`);
  return config;
});

export const useApps = (report_type?: string) => {
  const { organization } = useOrganization();

  const query = useQuery({
    enabled: !!report_type,
    queryKey: [
      `/apps/${report_type}/${organization?.publicMetadata.company_id as string}`,
    ],
    queryFn: async () => {
      const { data } = await client.get<App[]>(`/apps/${report_type}`);
      return data;
    },
  });

  return query;
};

export const useAppOptions = (report_type?: string) => {
  const query = useApps(report_type);
  const webOptions = query.data
    ?.filter((app) => app.app_type?.toLowerCase() === "web")
    .map((app) => ({
      value: app.app_id,
      label: `${app.app_name} - ${app.app_id}`,
    }));
  const mobileOptions = query.data
    ?.filter(
      (app) =>
        app.app_type?.toLowerCase() === "ios" ||
        app.app_type?.toLowerCase() === "android",
    )
    .map((app) => ({
      value: app.app_id,
      label: `${app.app_name} - ${app.app_id}`,
    }));
  return { ...query, data: { webOptions, mobileOptions } };
};

export const useBusinessUnit = (app_id?: string) => {
  const { organization } = useOrganization();

  const query = useQuery({
    enabled: !!app_id,
    queryKey: [
      `/business-units/${app_id}/${organization?.publicMetadata.company_id as string}`,
    ],
    queryFn: async () => {
      const { data } = await client.get<BusinessUnit[]>(`/business-units/${app_id}`);
      return data;
    },
  });

  return query;
};

export const useBusinessUnitOptions = (app_id?: string) => {
  const query = useBusinessUnit(app_id);
  const businessUnitOptions = query.data?.map((bu) => ({
    value: bu.business_unit_id,
    label: bu.business_unit_name,
    isDefault: bu.is_default,
  }));

  return { ...query, data: businessUnitOptions };
};

export const useMediaOptions = (appId?: string, report_type?: string) => {
  const query = useApps(report_type);
  const mediaOptions = query.data
    ?.find((app) => app.app_id === appId)
    ?.media_source.map((media) => ({
      value: media,
      label: media,
    }));
  return mediaOptions;
};

export const useEventOptions = (appId?: string, report_type?: string) => {
  const query = useApps(report_type);
  const eventOptions = query.data
    ?.find((app) => app.app_id === appId)
    ?.events?.map((event) => ({
      value: event,
      label: event,
    }));
  return eventOptions;
};
export const useSkan = (appId?: string, report_type?: string) => {
  const query = useApps(report_type);
  const skan = query.data?.find((app) => app.app_id === appId)?.skan;
  return skan;
};

export const useCountryOptions = () => {
  return useMemo(
    () =>
      Object.entries(countryMap)
        .map(([value, label]) => ({ value, label }))
        .sort((a, b) => (a.label < b.label ? -1 : a.label > b.label ? 1 : 0)),
    [],
  );
};

export const useCohortOptions = (): { label: string; value: number }[] => {
  return [
    { label: "0", value: 0 },
    { label: "1", value: 1 },
    { label: "3", value: 3 },
    { label: "7", value: 7 },
    { label: "14", value: 14 },
  ];
};

interface ReportFilters {
  appId?: string;
  appOs?: string;
  reportType: string;
  dateRange?: [Date, Date];
}

interface ScheduleFilters {
  appId?: string;
  reportType?: string;
}

const filterReports = (reports: BaseReport[], filters: ReportFilters) => {
  const { reportType, appId, appOs } = filters;
  const dateWeeks = filters.dateRange?.map((date) => format(date, "yyyy-MM-dd")) as
    | [string, string]
    | undefined;

  return reports.filter((report) => {
    return (
      (!reportType || report.report_type === reportType) &&
      (!appOs || appOs === getAppType(report.app_id)) &&
      (!dateWeeks ||
        (report.week_date >= dateWeeks[0] && report.week_date <= dateWeeks[1])) &&
      (!appId || report.app_id === appId)
    );
  });
};

export const useReports = (filters: ReportFilters) => {
  const { organization } = useOrganization();

  return useQuery({
    queryKey: [
      "reports",
      organization?.id,
      filters,
      filters.dateRange?.[0].toISOString(),
      filters.dateRange?.[1].toISOString(),
    ],
    queryFn: async () => {
      const { data } = await client.get<BaseReport[]>(`/reports`);
      const filteredReports = filterReports(data, filters);
      return { reports: filteredReports, total: filteredReports.length };
    },
    refetchInterval: 1000 * 10,
  });
};

export type Report = ParsedReport;
export type WowReport = ParsedReport & { report_type: "wow_report" | "wow_web" };

export const useReport = (reportId: string) => {
  return useQuery<ParsedReport, AxiosError>({
    queryKey: [`reports/${reportId}`],
    queryFn: async () => {
      const { data } = await client.get<ParsedReport>(`/reports/${reportId}`);
      return data;
    },
  });
};

export const useGenerateReport = () => {
  const { toast } = useToast();
  return useMutation({
    mutationKey: ["reports"],
    mutationFn: async (report: ReportInput) => {
      const { data } = await client.post<{ report_id: string }>(`/reports`, {
        ...report,
        summary_date: report.summary_date.toISOString().split("T")[0],
      });
      return data || [];
    },
    onSuccess: () => {
      toast({
        title: "Report generated",
        variant: "success",
      });
    },
    onError: () => {
      toast({
        title: "Error generating report",
        variant: "error",
      });
    },
  });
};

export const useEditReport = () => {
  const { toast } = useToast();
  return useMutation({
    mutationKey: ["reports"],
    mutationFn: async ({
      reportId,
      columns_state,
      texts_overrides,
    }: {
      reportId: string;
      columns_state?: object;
      texts_overrides?: Record<
        string,
        object[] | undefined | Record<string, string>
      >;
    }) => {
      const { data } = await client.patch<{ reportId: string }>(
        `/reports/${reportId}`,
        { columns_state, texts_overrides },
      );
      return data || [];
    },
    onSuccess: ({ reportId }) => {
      toast({
        title: "Changes saved successfully.",
        variant: "success",
      });
      void queryClient.invalidateQueries({ queryKey: [`reports/${reportId}`] });
    },
    onError: () => {
      toast({
        title: "Changes couldn't be saved.",
        variant: "error",
      });
    },
  });
};

export const useRegenerateReport = () => {
  const { toast } = useToast();
  return useMutation({
    mutationKey: ["reports"],
    mutationFn: async ({ reportId }: { reportId: string }) => {
      await client.put<{ reportId: string }>(`/reports/${reportId}`);
    },
    onSuccess: () => {
      toast({
        title: "Report was generated successfully.",
        variant: "success",
      });
      void queryClient.invalidateQueries({ queryKey: ["reports"] });
    },
    onError: () => {
      toast({
        title: "Report couldn't be generated.",
        variant: "error",
      });
    },
  });
};

export const useDeleteReport = () => {
  const { toast } = useToast();
  return useMutation({
    mutationKey: ["reportDelete"],
    mutationFn: async (reportId: string) => {
      await client.delete(`/reports/${reportId}`);
    },
    onSuccess: () => {
      toast({
        title: "Report deleted",
        variant: "success",
      });
      void queryClient.invalidateQueries({ queryKey: ["reports"] });
    },
    onError: () => {
      toast({
        title: "Error deleting report",
        variant: "error",
      });
    },
  });
};

export const useGenerateSchedule = () => {
  return useMutation({
    mutationKey: ["schedules"],
    mutationFn: async (report: ScheduleInput) => {
      await client.post(`/reports/schedules/create-schedule`, report);
    },
  });
};
export const useUpdateSchedule = () => {
  return useMutation({
    mutationKey: ["update-schedules"],
    mutationFn: async (report: ScheduleInput) => {
      await client.put(`/reports/schedules/update-schedule`, {
        ...report,
      });
    },
  });
};

const filterSchedules = (reports: Schedule[], filters: ScheduleFilters) => {
  const { reportType, appId } = filters;
  return reports.filter((report) => {
    return (
      (!reportType || report.report_type === reportType) &&
      (!appId || report.app_id === appId)
    );
  });
};

export const useSchedules = (filters: ScheduleFilters) => {
  const { organization } = useOrganization();

  return useQuery({
    queryKey: ["schedules", organization?.id, filters],
    queryFn: async () => {
      const { data } = await client.get<Schedule[]>(`/reports/schedules`);
      const filteredReports = filterSchedules(data, filters);
      return { reports: filteredReports, total: filteredReports.length };
    },
  });
};
export const useSchedule = (scheduleName: string) => {
  const query = useSchedules({ appId: undefined, reportType: undefined });

  const schedule = query.data?.reports.find(
    (report) => report.schedule_name === scheduleName,
  );
  return { ...query, data: schedule };
};

export const useDeleteSchedule = () => {
  return useMutation({
    mutationKey: ["schedules"],
    mutationFn: async (scheduleName: string) => {
      await client.delete(`/reports/schedules/${scheduleName}`);
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: ["schedules"] });
    },
  });
};

export const useComments = (sectionId: string) => {
  return useQuery<Comment[], AxiosError>({
    queryKey: [`comments/${sectionId}`],
    queryFn: async () => {
      const { data } = await client.get<Comment[]>(`/comments/${sectionId}`);
      return data;
    },
  });
};

export const useGenerateComment = (sectionId: string) => {
  const { toast } = useToast();
  return useMutation({
    mutationKey: ["comments"],
    mutationFn: async (comment: InputComment) => {
      await client.post(`/comments`, comment);
    },
    onMutate: async (newComment) => {
      const newCommentOptimistic = {
        ...newComment,
        createdAt: newComment.createdAt
          ? newComment.createdAt
          : new Date().toISOString(),
      };
      await queryClient.cancelQueries({ queryKey: [`comments/${sectionId}`] });

      const previousComments = queryClient.getQueryData([`comments/${sectionId}`]);

      queryClient.setQueryData([`comments/${sectionId}`], (old: Comment[]) => [
        ...old,
        newCommentOptimistic,
      ]);

      return { previousComments };
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: [`comments/${sectionId}`] });
    },
    onSettled: () => {
      void queryClient.invalidateQueries({ queryKey: [`comments/${sectionId}`] });
    },
    onError: () => {
      toast({
        title: "Note couldn't be generated.",
        variant: "error",
        duration: 3000,
      });
      void queryClient.invalidateQueries({ queryKey: [`comments/${sectionId}`] });
    },
  });
};

export const useReGenerateComment = (sectionId: string) => {
  const { toast } = useToast();
  return useMutation({
    mutationKey: ["comments"],
    mutationFn: async (comment: Comment) => {
      await client.post(`/comments`, comment);
    },
    onMutate: async (undoDeletedComment) => {
      await queryClient.cancelQueries({ queryKey: [`comments/${sectionId}`] });

      queryClient.setQueryData([`comments/${sectionId}`], (old: Comment[]) =>
        [...old, undoDeletedComment].sort((a, b) =>
          a.commentId.localeCompare(b.commentId),
        ),
      );

      return { undoDeletedComment };
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: [`comments/${sectionId}`] });
    },
    onSettled: () => {
      void queryClient.invalidateQueries({ queryKey: [`comments/${sectionId}`] });
    },
    onError: () => {
      toast({
        title: "Note couldn't be re-generated.",
        variant: "error",
        duration: 3000,
      });
      void queryClient.invalidateQueries({ queryKey: [`comments/${sectionId}`] });
    },
  });
};

export const useEditComment = (sectionId: string, commentId: string) => {
  const { toast } = useToast();
  return useMutation({
    mutationKey: [`comments/${sectionId}/${commentId}`],
    mutationFn: async ({
      sectionId,
      commentId,
      content,
    }: {
      sectionId: string;
      commentId: string;
      content?: object[];
    }) => {
      await client.patch(`/comments/${sectionId}/${commentId}`, {
        sectionId,
        commentId,
        content,
      });
    },
    onMutate: async (newComment) => {
      const newCommentOptimistic = {
        ...newComment,
        updatedAt: new Date().toISOString(),
      };
      await queryClient.cancelQueries({ queryKey: [`comments/${sectionId}`] });

      const previousComments = queryClient.getQueryData<Comment[]>([
        `comments/${sectionId}`,
      ]);

      queryClient.setQueryData(
        [`comments/${sectionId}`],
        (old: Comment[]) =>
          old?.map((comment) =>
            comment.commentId === newComment.commentId
              ? {
                  ...comment,
                  content: newCommentOptimistic.content,
                  updatedAt: newCommentOptimistic.updatedAt,
                }
              : comment,
          ) ?? [],
      );

      return { previousComments };
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: [`comments/${sectionId}`] });
    },
    onSettled: () => {
      void queryClient.invalidateQueries({ queryKey: [`comments/${sectionId}`] });
    },
    onError: () => {
      toast({
        title: "Your note couldn't be edited",
        variant: "error",
        duration: 3000,
      });
      void queryClient.invalidateQueries({ queryKey: [`comments/${sectionId}`] });
    },
  });
};

export const useDeleteComment = (sectionId: string) => {
  const { mutate: regenerateComment } = useReGenerateComment(sectionId);
  const { toast, dismiss } = useToast();
  return useMutation({
    mutationKey: ["comments"],
    mutationFn: async ({
      sectionId,
      commentId,
    }: {
      sectionId: string;
      commentId: string;
    }) => {
      await client.delete(`/comments/${sectionId}/${commentId}`);
    },
    onMutate: async (deleteComment) => {
      await queryClient.cancelQueries({ queryKey: [`comments/${sectionId}`] });

      const previousComments = queryClient.getQueryData<Comment[]>([
        `comments/${sectionId}`,
      ]);

      const commentDeleted = previousComments?.find(
        (comment) => comment.commentId === deleteComment.commentId,
      );

      return { commentDeleted };
    },
    onSuccess: (data, variables, context: { commentDeleted?: Comment }) => {
      queryClient.setQueryData(
        [`comments/${sectionId}`],
        (old: Comment[] | undefined) =>
          old?.filter(
            (comment) => comment.commentId !== context.commentDeleted?.commentId,
          ),
      );

      void queryClient.invalidateQueries({ queryKey: [`comments/${sectionId}`] });

      toast({
        title: "Your note was successfully deleted.",
        variant: "success",
        action: (
          <Button
            onClick={() => {
              regenerateComment(context.commentDeleted as Comment);
              dismiss();
            }}
            variant="link"
            className="text-success hover:text-success active:text-success"
          >
            Undo
          </Button>
        ),
        duration: 5000,
      });
    },
    onSettled: () => {
      void queryClient.invalidateQueries({ queryKey: [`comments/${sectionId}`] });
    },
    onError: () => {
      toast({
        title: "Your note couldn't be deleted",
        variant: "error",
        duration: 3000,
      });
      void queryClient.invalidateQueries({ queryKey: [`comments/${sectionId}`] });
    },
  });
};
